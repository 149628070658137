import { Outlet, redirect, type LoaderFunctionArgs } from 'react-router';
import ApplicationMark from '~/components/ui/application-mark';
import { Toaster } from '~/components/ui/toaster';
import { getUserToken } from '~/utils/session.server';

export async function loader({ request }: LoaderFunctionArgs) {
  const token = await getUserToken(request);
  if (token) {
    return redirect('/dashboard');
  }
  return null;
}

export default function PublicLayout() {
  return (
    <div className=" min-h-svh w-full grid grid-cols-1 md:grid-cols-2 px-4">
      <div className="flex justify-center items-center">
        <ApplicationMark />
      </div>
      <div className="flex justify-center items-center">
        <Outlet />
        <Toaster />
      </div>
    </div>
  );
}
